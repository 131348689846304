<template>
  <div>
    <input ref="reffileupload" id="reffileupload" name="reffileupload" type="file" v-show="false" v-on:change="doUpload()" />
    <v-dialog v-model="dialog" max-width="1200">
      <v-form ref="form" v-model="valid">
        <v-card :loading="loading">
          <v-card-title>Edit Update</v-card-title>
          <v-card-text>
            <v-text-field class="mb-2" outlined hide-details placeholder="Title" label="Title" dense v-model="formData.title" :rules="formDataRules.title"></v-text-field>
            <v-textarea class="mb-2" outlined hide-details placeholder="Detail" label="Detail" dense v-model="formData.body" :rules="formDataRules.body" min-height="100"></v-textarea>
            <v-select hide-details class="mb-2" dense outlined v-model="formData.published" :items="published_items" item-text="label" item-value="value" filled label="Published"></v-select>

            <div class="d-flex flex-row">
              <v-card :key="i" v-for="(_img, i) in formData.images">
                <v-img :src="_img" max-width="160" max-height="160" class="mr-1 rounded blue-grey lighten-4" style="cursor: pointer" contain @click="$refs.imageDialog.showDialog(resourceUrl(_img))"></v-img>
                <v-card-actions class="d-flex flex-row justify-center align-center w-100 py-0 px-0">
                  <v-btn dark text block color="red" @click="formData.images.splice(i, 1)">
                    <v-icon size="20">mdi-delete</v-icon> Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="mb-1"></div>
            <v-btn elevation="1" color="info" title="Add New" @click="browseFile()">
              <v-icon>mdi-plus</v-icon> Add Image
            </v-btn>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" elevation="1" @click="saveData()" :disabled="!valid" :loading="loading">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog(false)">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <ShowImageDialog ref="imageDialog"></ShowImageDialog>
  </div>
</template>

<script>
import ShowImageDialog from "@/components/ShowImageDialog.vue";

export default {
  components: {
    ShowImageDialog,
  },
  props: {
    campaign_no_: String,
    customer_no_: String,
  },
  data: () => ({
    dialog: null,
    valid: null,
    loading: null,
    formData: {
      id: "",
      title: "",
      body: "",
      campaign_no_: "",
      customer_no_: "",
      published: null,
      images: [],
    },
    formDataRules: {
      title: [(v) => !!v || "Title is required"],
      body: [(v) => !!v || "Body is required"],
      // campaign_no_: [(v) => !!v || "Campaign No. is required"],
      // customer_no_: [(v) => !!v || "Customer No. is required"],
      images: [],
    },
    published_items: [
      {
        label: 'Y', value: true
      },
      {
        label: 'N', value: false
      },
    ]
  }),

  methods: {
    async getData(id = null) {
      this.showLoadingOverlay(true);

      if (id == null) return;
      if (id <= 0) return;

      var params = {
        id: id,
      };
      await this.$axios
        .get("project-progress/detail", { params: params })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.formData.id = resData.data.project_progress.id;
            this.formData.title = resData.data.project_progress.title;
            this.formData.body = resData.data.project_progress.body;
            this.formData.campaign_no_ =
              resData.data.project_progress.campaign_no_;
            this.formData.customer_no_ =
              resData.data.project_progress.customer_no_;
            this.formData.published =
              resData.data.project_progress.published;
            this.formData.images = resData.data.project_progress.images;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.closeDialog();
          this.axiosErrorHandler(error);
        });
    },

    async doUpload() {
      this.showLoadingOverlay(true);

      if (this.campaign_no_ == null || this.customer_no_ == null) {
        this.showAlert("error", "Campaign and Customer not found!");
        this.showLoadingOverlay(false);
        return;
      }

      if (this.campaign_no_ == "" || this.customer_no_ == "") {
        this.showAlert("error", "Campaign and Customer not found!");
        this.showLoadingOverlay(false);
        return;
      }

      var fileupload = this.$refs.reffileupload.files[0];

      // console.log(fileupload);

      var formData = new FormData();
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("img_src", fileupload);
      await this.$axios
        .post("project-progress/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);

          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.formData.images.push(resData.data.file_src);

            console.log(this.formData.images);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    browseFile() {
      this.$refs.reffileupload.click();
    },

    async saveData() {
      this.$refs.form.validate();
      this.formData.campaign_no_ = this.campaign_no_;
      this.formData.customer_no_ = this.customer_no_;

      if (!this.valid) return;

      this.showLoadingOverlay(true);
      let formData = new FormData();
      formData.append("id", this.formData.id);
      formData.append("campaign_no_", this.formData.campaign_no_);
      formData.append("customer_no_", this.formData.customer_no_);
      formData.append("title", this.formData.title);
      formData.append("body", this.formData.body);
      formData.append("published", this.formData.published);
      formData.append("images", JSON.stringify(this.formData.images));
      await this.$axios
        .post("project-progress/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.closeDialog();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    clearData() {
      this.valid = null;
      this.formData.id = null;
      this.formData.campaign_no_ = this.campaign_no_;
      this.formData.customer_no_ = this.customer_no_;
      this.formData.title = null;
      this.formData.body = null;
      this.formData.images = [];
    },

    runCallback() {
      this.$emit("callback");
    },

    showDialog(dialog = true) {
      this.clearData();
      this.dialog = dialog;
    },

    closeDialog() {
      this.showDialog(false);
      this.callback();
    },
  },

  mounted() {
    this.clearData();
  },
};
</script>
