<template>
  <v-card :loading="loading">
    <v-toolbar dense elevation="0">
      <v-toolbar-title>Contacts</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getData()"><v-icon>mdi-sync</v-icon></v-btn>
        </template>
        <span> Refresh </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="add()"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span> Add </span>
      </v-tooltip>
    </v-toolbar>

    <v-list>
      <v-list-item two-line v-for="(row, n) in contacts" :key="n">
        <v-list-item-content>
          <v-list-item-title>{{ row.first_name }}</v-list-item-title>
          <v-list-item-subtitle>{{ row.email }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-spacer></v-spacer>
        <v-btn icon @click="$refs.refContactDetailDialog.getDetail(row.contact_id);"><v-icon>mdi-eye</v-icon></v-btn>
        <v-btn icon @click="del(row.id)"><v-icon>mdi-delete</v-icon></v-btn>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card min-height="600" max-height="600">
          <v-toolbar elevation="0">
            <v-toolbar-title>Add Contact</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="dialog = !dialog"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="mb-0 pb-0">
            <v-text-field class="mb-0 pb-0" v-model="search" label="Search" @keyup="findContact" hide-details=""></v-text-field>
          </v-card-text>
          <v-card-text class="px-0" style="height:400px;overflow-y:auto">
            <v-alert color="warning" dark class="mx-4" v-if="search == ''">please type in the search box</v-alert>
            <v-alert color="warning" dark class="mx-4" v-if="search != '' && foundContacts.length == 0 && loadingSearch == false">Contact not found</v-alert>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader v-if="loadingSearch" boilerplate elevation="0" class="mb-2" :loading="loadingSearch" type="list-item-two-line"></v-skeleton-loader>
            <v-list>
              <v-list-item-group v-model="selectedContact" color="primary">
                <v-list-item two-line v-for="(row, n) in foundContacts" :key="n">
                  <v-list-item-content>
                    <v-list-item-title>{{ row.first_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ row.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-icon v-if="selectedContact == n">mdi-check</v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions style="bottom: 0px; position: absolute; display: flex; width: 100%">
            <v-spacer></v-spacer>
            <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
            <v-btn color="primary" @click="save">Sumbit</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <ContactDetailDialog ref="refContactDetailDialog"></ContactDetailDialog>
  </v-card>
</template>

<script>
import ContactDetailDialog from '@/components/contact/ContactDetailDialog.vue';

export default {
  components: {
    ContactDetailDialog,
  },
  props: {
    no_: String,
    customer_no_: String,
  },
  data: () => ({
    loading: false,
    loadingSearch: false,
    valid: null,
    dialog: false,
    id: 0,
    campaign_no_: null,
    search: "",
    contacts: [],
    foundContacts: [],
    selectedContact: {},
  }),
  methods: {
    getData() {
      this.contacts = [];
      this.loading = true;
      this.campaign_no_ = this.no_;

      var params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      this.$axios
        .get("campaign/data-contact", {
          params: params,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.contacts = resData.data.contacts;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    findContact() {
      this.foundContacts = [];
      this.loadingSearch = true;
      this.campaign_no_ = this.no_;


      if (this.search == '') {
        this.loadingSearch = false;
        return;
      }

      var params = {
        search: this.search,
      };
      this.$axios
        .get("campaign/find-contact", {
          params: params,
        })
        .then((res) => {
          this.loadingSearch = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.foundContacts = resData.data.contacts;
          } else {
            console.log(resData.status);
            console.log(resData.message);
            // this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loadingSearch = false;
          this.axiosErrorHandler(error);
        });
    },
    add() {
      this.id = null;
      this.dialog = true;
    },
    save() {
      // this.file_src = this.$refs.reffileupload.contacts[0];

      this.loading = true;

      if (this.selectedContact == null) {
        this.showAlert("error", "Select Contact");
      }
      this.id = this.foundContacts[this.selectedContact].id;
      var contact_no_ = this.foundContacts[this.selectedContact].no_;

      let formData = new FormData();
      formData.append("contact_no_", contact_no_);
      formData.append("contact_id", this.id);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);

      this.$axios
        .post("campaign/save-contact", formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == 'success') {
            this.dialog = false;
            this.getData();
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    del(id) {
      if (confirm("delete?") == false) return;

      this.loading = true;

      let formData = new FormData();
      formData.append("id", id);

      this.$axios
        .post("campaign/delete-contact", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>