var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{staticClass:"mb-4",attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Technical Drawing")]),_c('v-spacer'),(!_vm.isMobile)?_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","autofocus":"","outlined":"","rounded":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":"","small":""},model:{value:(_vm.datatable.search),callback:function ($$v) {_vm.$set(_vm.datatable, "search", $$v)},expression:"datatable.search"}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","title":"Refresh"},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-btn',{attrs:{"icon":"","title":"Add New"},on:{"click":function($event){return _vm.addNew()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{staticClass:"mb-3 my-2",attrs:{"loading":_vm.datatable.loading,"loading-text":_vm.datatable.loadingText,"headers":_vm.datatable.columns,"items":_vm.datatable.data,"search":_vm.datatable.search,"hide-default-header":_vm.isMobile,"dense":""},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"60px"}},[_c('v-btn',{attrs:{"plain":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{staticClass:"mx-0",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"plain":"","icon":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteData(item)}}},[_c('v-icon',{staticClass:"mx-0",attrs:{"size":"16"}},[_vm._v("mdi-delete")])],1)],1)]}}:null,(!_vm.isMobile)?{key:"item.doc_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"260px"}},[_c('span',{staticClass:"mr-2",on:{"click":function($event){return _vm.editData(item)}}},[_vm._v(" "+_vm._s(item.doc_name)+" ")])])]}}:null,(!_vm.isMobile)?{key:"item.file_src",fn:function(ref){
var item = ref.item;
return [(item.file_src != '')?_c('v-btn',{attrs:{"plain":"","icon":"","x-small":"","color":"primary","target":"_blank","href":_vm.resourceUrl(item.file_src)}},[_c('v-icon',{staticClass:"mx-0",attrs:{"size":"16"}},[_vm._v("mdi-link")])],1):_vm._e()]}}:null,(_vm.isMobile)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"4","outlined":""}},[_c('v-card-title',{staticClass:"text-h5 mb-1 pb-1"},[_vm._v(_vm._s(item.doc_name))]),_c('v-card-subtitle',{staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(item.upload_date)),_c('br'),_vm._v(" "+_vm._s(item.upload_by)+" ")]),_c('v-card-actions',{staticClass:"d-flex align-center flex-row mx-2"},[_c('span',{staticClass:"text--disabled",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.optionYesNo(item.published)))]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.editData(item)}}},[_vm._v("Detail")])],1)],1)]}}:null],null,true)}),_c('TechnicalDrawingAdd',{ref:"formAdd",attrs:{"campaign_no_":_vm.campaign_no_,"customer_no_":_vm.customer_no_,"callback":_vm.callbackAdd}}),_c('TechnicalDrawingEdit',{ref:"formEdit",attrs:{"campaign_no_":_vm.campaign_no_,"customer_no_":_vm.customer_no_,"callback":_vm.callbackEdit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }