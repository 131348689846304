<template>
  <div>
    <v-card elevation="0">
      <v-toolbar dense elevation="0" class="mb-4">
        <v-toolbar-title>Technical Drawing</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="datatable.search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line small></v-text-field>

        <v-btn icon @click="getData()" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>

        <v-btn icon @click="addNew()" title="Add New">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="datatable.search" :hide-default-header="isMobile" dense class="mb-3 my-2">
        <template v-slot:[`item.id`]="{ item }" v-if="!isMobile">
          <div style="min-width: 60px">
            <v-btn plain icon x-small color="primary" @click="editData(item)">
              <v-icon size="16" class="mx-0">mdi-pencil</v-icon>
            </v-btn>
            <v-btn plain icon x-small color="error" @click="deleteData(item)">
              <v-icon size="16" class="mx-0">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.doc_name`]="{ item }" v-if="!isMobile">
          <div style="min-width: 260px">
            <span class="mr-2" @click="editData(item)">
              {{ item.doc_name }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.file_src`]="{ item }" v-if="!isMobile">
          <v-btn plain icon x-small color="primary" target="_blank" :href="resourceUrl(item.file_src)" v-if="item.file_src != ''">
            <v-icon size="16" class="mx-0">mdi-link</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">{{ item.doc_name }}</v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.upload_date }}<br />
              {{ item.upload_by }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <span class="text--disabled" style="font-size: 12px">{{
                optionYesNo(item.published)
              }}</span>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="primary" @click="editData(item)">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>

      <TechnicalDrawingAdd :campaign_no_="campaign_no_" :customer_no_="customer_no_" :callback="callbackAdd" ref="formAdd"></TechnicalDrawingAdd>
      <TechnicalDrawingEdit :campaign_no_="campaign_no_" :customer_no_="customer_no_" :callback="callbackEdit" ref="formEdit"></TechnicalDrawingEdit>
    </v-card>
  </div>
</template>

<script>
import TechnicalDrawingAdd from "./TechnicalDrawingAdd.vue";
import TechnicalDrawingEdit from "./TechnicalDrawingEdit.vue";

export default {
  components: { TechnicalDrawingAdd, TechnicalDrawingEdit },

  props: {
    campaign_no_: String,
    customer_no_: String,
  },

  data: () => ({
    datatable: {
      search: null,
      loading: null,
      loadingText: "Loading... Please wait...",
      columns: [
        { text: "ID", value: "id" },
        { text: "Name", value: "doc_name" },
        { text: "Description", value: "description" },
        { text: "Source", value: "file_src" },
        { text: "Remarks", value: "remarks" },
        { text: "Upload Date", value: "upload_date" },
        { text: "Upload By", value: "upload_by" },
        { text: "Published", value: "published" },
      ],
      data: [],
    },
  }),

  methods: {
    async getData(refresh = false) {
      if (refresh) this.AxiosStorageRemove("GET", "technicaldrawing/data");

      this.datatable.loading = true;
      var params = {};
      params = {
        campaign_no_: this.campaign_no_,
        customer_no_: this.customer_no_,
      };
      await this.$axios
        .get("technicaldrawing/data", {
          params: params,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.technical_drawing;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.datatable.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    addNew() {
      this.$refs.formAdd.showDialog();
    },

    callbackAdd() {
      this.getData(true);
    },

    editData(data) {
      this.$refs.formEdit.showDialog(data.id);
    },

    callbackEdit() {
      this.getData(true);
    },

    async deleteData(item) {
      if (!confirm('Delete "' + item.doc_name + '"?')) return;

      var postUri = "technicaldrawing/delete";

      let formData = new FormData();
      formData.append("id", item.id);

      this.showLoadingOverlay(true);

      this.$axios
        .post(postUri, formData)
        .then((res) => {
          var resData = res.data;
          this.showLoadingOverlay(false);
          if (resData.status == "success") {
            this.getData();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getData();

    this.$nextTick(function () {
      // console.log("333 ", this.$refs.statusSubtitle)
    });
  },
};
</script>
