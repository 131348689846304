<template>
  <div>
    <v-dialog v-model="add.dialog" max-width="600" eager>
      <v-card>
        <v-toolbar dense elevation="0" class="mb-4">
          <v-toolbar-title>New Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="add.dialog = !add.dialog" title="Close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="addform" v-model="add.valid" lazy-validation>
            <v-text-field hide-details class="mb-2" dense outlined label="Document Name" v-model="add.data.doc_name" :rules="add.rules.doc_name" :error-messages="add.error_message.doc_name"></v-text-field>
            <v-textarea hide-details class="mb-2" dense outlined label="Description" rows="2" v-model="add.data.description" :rules="add.rules.description" :error-messages="add.error_message.description"></v-textarea>
            <v-text-field hide-details class="mb-2" dense outlined name="name" label="File Source" v-model="add.data.file_src" :rules="add.rules.file_src" :error-messages="add.error_message.file_src" :append-outer-icon="'mdi-paperclip'" @click:append-outer="browseFile()"></v-text-field>
            <v-text-field hide-details class="mb-2" dense outlined label="Remarks" v-model="add.data.remarks"></v-text-field>
            <v-select hide-details class="mb-2" dense outlined v-model="add.data.published" :items="published_items" item-text="label" item-value="value" filled label="Published"></v-select>
            <span class="text-caption">Max upload file : 8MB</span>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveData()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="add.dialog = !add.dialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input ref="reffileupload" id="reffileupload" name="reffileupload" type="file" v-show="false" v-on:change="doUpload()" />
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    campaign_no_: String,
    customer_no_: String,
  },

  data: () => ({
    published_items: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    add: {
      form: null,
      valid: null,
      loading: null,
      data: {
        id: "",
        doc_name: "",
        description: "",
        file_src: "",
        remarks: "",
        upload_date: "",
        upload_by: "",
        published: "",
      },
      rules: {
        id: "",
        doc_name: [(v) => !!v || "Doc Name is required"],
        description: [(v) => !!v || "Description is required"],
        file_src: [(v) => !!v || "File Source is required"],
        remarks: [(v) => !!v || "Remarks is required"],
        upload_date: "",
        upload_by: "",
        published: "",
      },
      error_message: {
        id: "",
        doc_name: "",
        description: "",
        file_src: "",
        remarks: "",
        upload_date: "",
        upload_by: "",
        published: "",
      },
      dialog: null,
    },
  }),

  methods: {
    showDialog() {
      this.add.dialog = true;
      this.add.data.id = "";
      this.add.data.doc_name = "";
      this.add.data.description = "";
      this.add.data.file_src = "";
      this.add.data.remarks = "";
      this.add.data.upload_date = "";
      this.add.data.upload_by = "";
      this.add.data.published = "";
      this.add.valid = false;

      this.$refs.addform.reset();
    },

    browseFile() {
      this.$refs.reffileupload.click();
    },

    async doUpload() {
      this.showLoadingOverlay(true);

      var fileupload = this.$refs.reffileupload.files[0];

      // console.log(fileupload);

      var formData = new FormData();
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("fileupload", fileupload);
      formData.append("path", "campaign/" + this.campaign_no_);
      await this.$axios
        .post("technicaldrawing/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);

          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            // console.log(resData.data.file_src);
            this.add.data.file_src = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      // validasi
      this.add.valid = this.$refs.addform.validate();

      if (this.add.valid === false) {
        this.showAlert("error", "Please complete the form");
        return false;
      }

      var postUri = "technicaldrawing/save";

      let formData = new FormData();
      formData.append("doc_name", this.add.data.doc_name);
      formData.append("description", this.add.data.description);
      formData.append("file_src", this.add.data.file_src);
      formData.append("remarks", this.add.data.remarks || '');
      formData.append("published", this.add.data.published);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);

      this.add.loading = true;
      this.$axios
        .post(postUri, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.add.loading = false;
          var resData = res.data;
          console.log(resData.status);
          if (resData.status == "success") {
            this.add.dialog = false;
            this.runCallback();
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.add.loading = false;
          this.add.error_message = error.response.data.data;
          this.axiosErrorHandler(error);
        });
    },

    runCallback() {
      this.$emit("callback");
    },
  },

  mounted() { },
};
</script>
