<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card v-if="(typeof dataDetail.contact !== 'undefined')">
      <v-img :src="resourceUrl(dataDetail.contact.picture)" :lazy-src="resourceUrl(dataDetail.contact.picture)" height="200" contain class="grey darken-1"></v-img>
      <v-card-title>{{ dataDetail.contact.first_name + ' ' + dataDetail.contact.last_name }}</v-card-title>
      <v-card-subtitle>
        {{ dataDetail.contact.company_name }}
      </v-card-subtitle>


      <v-card-actions>
        <v-btn color="orange lighten-2" text @click="showDetail = !showDetail"> View Detail </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>{{ showDetail ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetail">
          <v-divider></v-divider>
          <div style="max-height: 275px; overflow-y: auto">
            <v-card-text>
              <v-list-item>
                <v-list-item-title>First Name</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.first_name }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Last Name</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.last_name }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.email }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.type_ }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Job Title</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.job_title }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Company Name</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.company_name }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Office No.</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ dataDetail.contact.office_no }}</v-list-item-subtitle>
              </v-list-item>
            </v-card-text>
          </div>
        </div>
      </v-expand-transition>

      <v-card-actions>
        <v-btn @click.stop="dialog = !dialog">Close</v-btn>
        <v-spacer></v-spacer>
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  data() {
    return {
      dialog: false,

      dataDetail: {},
      showDetail: false,
    };
  },

  methods: {

    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },

    getDetail(id) {
      this.dialog = false;

      this.showLoadingOverlay(true);
      var params = {
        id: id,
      };
      this.$axios
        .get("contact/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.dataDetail = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.dialog = true;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
}
</script>