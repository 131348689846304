<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-toolbar elevation="0">
          <v-toolbar-title>Upload Attachment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="dialog = !dialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="py-2">
          <v-text-field class="d-none" v-model="id"></v-text-field>
          <v-text-field v-model="file_src" :rules="file_srcRules" append-icon="mdi-upload" @click:append="uploadFile(1)" placeholder="File Url" label="File Url" dense outlined hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="file_caption" :rules="file_captionRules" placeholder="Camption" label="Caption" dense outlined hide-details class="mb-2"></v-text-field>
          <v-textarea v-model="file_description" label="Description" rows="4" dense outlined hide-details class="mb-2"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="save()">Sumbit</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    dialog: false,
    id: 0,
    campaign_no_: null,
    customer_no_: null,
    file_src: null,
    file_caption: null,
    file_description: null,

    file_srcRules: [(v) => !!v || "Image is required"],
    file_captionRules: [(v) => !!v || "Caption is required"],
  }),

  methods: {
    showDialog(data) {
      this.dialog = true
      this.id = data.id;
      this.campaign_no_ = data.campaign_no_;
      this.customer_no_ = data.customer_no_;
      this.file_src = data.file_src;
      this.file_caption = data.file_caption;
      this.file_description = data.file_description;
    },

    clearForm() {
      this.id = null;
      this.campaign_no_ = null;
      this.customer_no_ = null;
      this.file_src = null;
      this.file_caption = null;
      this.file_description = null;
    },

    runCallback() {
      this.$emit("callback");
    },

    uploadFile() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.file_src = "";

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("campaign/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.file_src = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    save() {
      // this.file_src = this.$refs.reffileupload.files[0];

      this.loading = true

      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("file_src", this.file_src);
      formData.append("file_caption", this.file_caption);
      formData.append("file_description", this.file_description);

      this.$axios
        .post("campaign/update-attachment", formData)
        .then((res) => {
          this.loading = false
          var resData = res.data;
          if (resData.status == "success") {
            this.dialog = false;
            this.clearForm();
            this.runCallback();
          }
          this.showAlert(resData.status, resData.message);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
  },
};
</script>