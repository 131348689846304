<template>
  <div>
    <page-toolbar :title="'Campaign Detail'"> </page-toolbar>
    <v-container>
      <page-title :title="'Campaign Detail'">
        Campaign Detail <span v-if="!loading">({{ $route.query.no_ }})</span>
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-4" :loading="loading">
            <v-toolbar dense elevation="0">
              <v-toolbar-title>General</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="4">
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Customer No.:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      customer.no_
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Cust. Name:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      customer.name
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Cust. Address:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      customer.city
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col md="4">
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Campaign No.:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.no_
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Description:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.description
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Starting Date:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      appDateFormat(campaign.starting_date)
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Ending Date:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      appDateFormat(campaign.ending_date)
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>City:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.city_code
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col md="4">
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Status:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.status
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Status Code.:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.status_code
                    }}</v-col>
                  </v-row>
                  <v-row class="my-1">
                    <v-col cols="12" md="4" class="py-0"><b>Sub Status OM:</b></v-col>
                    <v-col cols="12" md="8" class="py-0">{{
                      campaign.substatus_om
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col>
                <v-tabs v-model="tab">
                  <v-tab>Images</v-tab>
                  <v-tab>Attachments</v-tab>
                  <v-tab>Users</v-tab>
                  <v-tab>Contacts</v-tab>
                  <v-tab>Location</v-tab>
                  <v-tab>Progress</v-tab>
                  <v-tab>Document Order</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="pa-4">
                  <v-tab-item>
                    <CampaignImages ref="refCampaignImages" class="mb-4" :no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></CampaignImages>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignAttachments ref="refCampaignAttachments" class="mb-4" :no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></CampaignAttachments>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignUsers ref="refCampaignUsers" class="mb-4" :no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></CampaignUsers>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignContact ref="refCampaignUsers" class="mb-4" :no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></CampaignContact>
                  </v-tab-item>
                  <v-tab-item>
                    <CampaignLocation ref="refCampaignUsers" class="mb-4" :no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></CampaignLocation>
                  </v-tab-item>
                  <v-tab-item>
                    <ProjectProgress :campaign_no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></ProjectProgress>
                  </v-tab-item>
                  <v-tab-item>
                    <DocumentOrder :campaign_no_="$route.query.no_" :customer_no_="$route.query.customer_no_"></DocumentOrder>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CampaignImages from "./components/CampaignImages.vue";
import CampaignAttachments from "./components/CampaignAttachments.vue";
import CampaignUsers from "./components/CampaignUsers.vue";
import CampaignContact from "./components/CampaignContact.vue";
import CampaignLocation from "./components/CampaignLocation.vue";
import ProjectProgress from "./components/ProjectProgress.vue";
import DocumentOrder from "./components/DocumentOrder.vue";

export default {
  components: {
    CampaignImages,
    CampaignAttachments,
    CampaignUsers,
    CampaignContact,
    CampaignLocation,
    ProjectProgress,
    DocumentOrder
  },

  data() {
    return {
      loading: false,
      breadcrumbItems: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/campaign",
          to: { name: "Campaign" },
        },
        {
          text: "Detail",
          disabled: true,
          to: { name: "Campaign" },
        },
      ],
      tab: null,
      campaign: {},
      images: [],
      customer: {},
    };
  },

  methods: {
    getData(refresh = false) {
      if (refresh) this.AxiosStorageRemove("GET", "campaign/data-detail");
      this.loading = true;
      var params = {
        no_: this.$route.query.no_,
        customer_no_: this.$route.query.customer_no_,
      };
      this.$axios
        .get("campaign/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.campaign = resData.data.campaign;
            this.images = resData.data.images;
            this.customer = resData.data.customer;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  computed: {
    atest() {
      console.log(this.selectedCutomer.no_);
      return "";
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData(true);
  },
};
</script>
