<template>
  <div>
    <v-card text elevation="1">
      <v-tabs v-model="tab">
        <v-tab>Technical Drawing</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="">
        <v-tab-item>
          <TechnicalDrawing
            :campaign_no_="campaign_no_"
            :customer_no_="customer_no_"
          ></TechnicalDrawing>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import TechnicalDrawing from "./TechnicalDrawing.vue";

export default {
  components: { TechnicalDrawing },

  props: {
    campaign_no_: String,
    customer_no_: String,
  },

  data: () => ({
    tab: null,
  }),

  methods: {},

  mounted() {},
};
</script>
