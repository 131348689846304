<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="showDialog()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add New Image
        </v-card-title>

        <v-card-text class="py-2">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="img_src" append-icon="mdi-upload" @click:append="uploadFile(1)" placeholder="Image Url" label="Image Url" dense outlined hide-details class="mb-2"></v-text-field>
              <v-text-field v-model="img_caption" class="mb-2" placeholder="Image Caption" label="Image Caption" dense outlined hide-details></v-text-field>
              <v-textarea v-model="img_description" class="mb-2" placeholder="Image Description" label="Image Description" dense outlined hide-details row-height="2"></v-textarea>
              <v-select :items="img_primary_items" item-text="text" item-value="value" v-model="img_primary" class="mb-2" placeholder="Image Primary" label="Image Primary" dense outlined hide-details></v-select>
            </v-col>
            <v-col cols="6">
              <v-img :src="img_src" max-height="250"></v-img>
            </v-col>
          </v-row>
          <!-- <v-text-field v-model="campaign_no_" class="mb-2" placeholder="Campaign No." label="Campaign No." dense outlined hide-details></v-text-field>
          <v-text-field v-model="customer_no_" class="mb-2" placeholder="Customer No." label="Customer No." dense outlined hide-details></v-text-field>
          <v-text-field v-model="img_src" append-icon="mdi-upload" @click:append="uploadFile(1)" placeholder="Image Url" label="Image Url" dense outlined hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="img_caption" class="mb-2" placeholder="Image Caption" label="Image Caption" dense outlined hide-details></v-text-field>
          <v-textarea v-model="img_description" class="mb-2" placeholder="Image Description" label="Image Description" dense outlined hide-details row-height="2"></v-textarea>
          <v-select :items="img_primary_items" item-text="text" item-value="value" v-model="img_primary" class="mb-2" placeholder="Image Primary" label="Image Primary" dense outlined hide-details></v-select> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="save()">
            Submit
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" accept="image/*" />

  </div>
</template>

<script>
export default {
  props: {
    campaign_no_: {
      type: String,
      default: ''
    },
    customer_no_: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      dialog: null,
      img_src: null,
      img_caption: null,
      img_description: null,
      img_primary: null,
      img_srcRules: [(v) => !!v || "Image is required"],
      img_captionRules: [(v) => !!v || "Caption is required"],
      // img_descriptionRules: [v => !!v || 'Name is required',],
      img_primaryRules: [(v) => !!v || "Primary is required"],
      img_primary_items: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    }
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },

    runCallback() {
      this.$emit("callback");
    },

    uploadFile() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.img_src = "";

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("campaign/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.img_src = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    save() {
      this.showLoadingOverlay(true);
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("campaign_no_", this.campaign_no_);
      formData.append("customer_no_", this.customer_no_);
      formData.append("img_src", this.img_src);
      formData.append("img_caption", this.img_caption);
      formData.append("img_description", this.img_description);
      formData.append("img_primary", this.img_primary);
      this.$axios
        .post("campaign/save-image", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.clear();
            this.dialog = false;
            this.runCallback();
          }
          this.showAlert(resData.status, resData.message);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    clear() {
      this.img_src = null;
      this.img_caption = null;
      this.img_description = null;
      this.img_primary = null;
    }
  }
}
</script>