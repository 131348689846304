var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"1"}},[_c('v-toolbar',{staticClass:"mb-4",attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Project Progress")]),_c('v-spacer'),(!_vm.isMobile)?_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","autofocus":"","outlined":"","rounded":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":"","small":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","title":"Refresh"},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-btn',{attrs:{"icon":"","title":"Add New"},on:{"click":function($event){return _vm.addNew()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),(_vm.isMobile)?_c('div',{staticClass:"px-2"},[_c('v-text-field',{staticClass:"mb-2",staticStyle:{"max-width":"3000px"},attrs:{"dense":"","clearable":"","autofocus":"","outlined":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"mb-3 my-2",attrs:{"loading":_vm.datatable.loading,"loading-text":_vm.datatable.loadingText,"headers":_vm.datatable.columns,"items":_vm.datatable.data,"search":_vm.search,"hide-default-header":_vm.isMobile,"dense":""},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","text":"","size":"8"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","text":"","size":"8"},on:{"click":function($event){return _vm.deleteData(item.id)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-delete")])],1)],1)]}}:null,(!_vm.isMobile)?{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.editData(item)}}},[_vm._v(_vm._s(item.title))])]}}:null,(!_vm.isMobile)?{key:"item.published",fn:function(ref){
var item = ref.item;
return [(item.published)?_c('span',[_vm._v("Y")]):_c('span',[_vm._v("N")])]}}:null,(_vm.isMobile)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"4","outlined":""}},[_c('v-card-title',{staticClass:"text-h5 mb-1 pb-1"},[_vm._v("item.title"+_vm._s(item.title))]),_c('v-card-subtitle',{staticClass:"my-0 py-0"},[_vm._v(" "+_vm._s(item.document_date)),_c('br'),_vm._v(" "+_vm._s(item.campaign_description)+" ")]),_c('v-card-actions',{staticClass:"d-flex align-center flex-row mx-2"},[_c('span',{staticClass:"text--disabled",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.document_date))]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.editData(item)}}},[_vm._v("Detail")])],1)],1)]}}:null],null,true)})],1),_c('ProjectProgressAdd',{ref:"formAdd",attrs:{"campaign_no_":this.campaign_no_,"customer_no_":this.customer_no_},on:{"callback":_vm.addCallback}}),_c('ProjectProgressEdit',{ref:"formEdit",attrs:{"campaign_no_":this.campaign_no_,"customer_no_":this.customer_no_,"selected_id":_vm.selectedId},on:{"callback":_vm.editCallback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }